// Styles for Forms
// -----------------------------------------------------------------------------

@media all and (-ms-high-contrast: none) and (min-width : 992px), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
     .webform-client-form {
     		margin-top: -1em;
     }
}

.form-item {
	transition: all ease-in-out 0.15s;
}

.disabled-field {
	pointer-events: none;
	
	opacity: 0.3;

	select {
		visibility: hidden;
	}
}

.page-node-2065 {
	.field-name-body {
		max-width: 700px;
		margin: 0 auto;
	}
}


#webform-client-form-2065 {
	margin: 0 auto;
	max-width: 500px;
	.webform-container-inline > label {
	    min-width: 130px;
	    display: inline-block;
	}
	.webform-component--email {
		label .form-required {
			display: none;
		}
	}
	input#edit-submitted-email {
	    background: rgba(0,0,0,0.1);
	    font-size: 1.3em;
	    padding: 1em;
	    height: 2.4em;
	    font-style: italic;
	    text-align: center;
	    cursor:default;

	    &:focus {
	    	border-color: transparent !important; 
	    }
	}
}


fieldset.webform-component--other-organisation {
	background: transparent;
	border: 0 none;
}
.webform-component--organisation {
	position: relative;
	transition: all ease-in-out 0.15s;
	select.chosen-processed {
		display: block !important;
		// opacity: 0;

		top: 1.7em;
		position: absolute;
	}


  .form-select {
    display: block !important;
    box-shadow: none !important;

  }
  .chosen-container {
  	background: transparent;
  	box-shadow: 0 none;
    width: 99.9% !important;
    padding: 0 !important;
    left: 1px;
    .chosen-single {
      background: white;
      border-color: transparent;
      box-shadow: none;
      min-height: 1.2em;
      color: $gray-light;
      height: 100%;
      line-height: 3em;
      box-shadow: 0 0 60px rgba(0,0,0,0.03);

      div {
      	width: 15px;
      	top: 1em;
      }
      span {
      	padding: 0 1em;
      }
    }

    .chosen-search {
    	padding: 0;
    	input {
	    	padding: 0em 1.5em;
	    	height: 3.2em;
	    	border: 0 none;
	    	background: transparent;
	    }
    }
    &.chosen-container-active {
    	.chosen-single {
    		border-color: $genio-pink;
    	}
    }
    .chosen-drop {
    	left: 0 !important;
    	top:5px;
    	display: none;
    	opacity: 0;
    	transition: all ease-in-out 0.15s;
    	border-color: $genio-pink;
    }
    &.chosen-with-drop {
    	.chosen-drop {
    		display: block;
    		opacity: 1;
    	}
    }
  }
  ul.chosen-results {
  	margin: 0.4em 0;
  	padding: 0;
  	li {
  		padding: 0.7em 1em 0.4em;
  	}
    li.highlighted {
      background: $genio-purple;
    }
  }
}



.webform-client-form {
	
	input.webform-submit {
		background-color: $genio-pink;
		color: white;
		font-family: $font-medium;
		border-radius: 0px;
		padding: 1em 3em;
		margin: 1em auto;
		display: block;
		&:hover {
			background-color: darken($genio-pink, 5%);
		}
	}
	.form-control {
		box-shadow: none;	
		padding: 0em 1.5em;
		max-width: 100%;
		margin: 0;
		// line-height: 1em;
		height: 3.2em;
		color: $gray-light;
		box-shadow: 0 0 60px rgba(0,0,0,0.03);
		&.form-textarea {
			padding: 1em 1.5em;
			height: auto;
		}
		&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		    color:    $gray-lighter !important;
		}
		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		   color:    $gray-lighter !important;
		   opacity:  1;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
		   color:    $gray-lighter !important;
		   opacity:  1;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		   color:    $gray-lighter !important;
		}
		&::-ms-input-placeholder { /* Microsoft Edge */
		   color:    $gray-lighter !important;
		}
	}

}

.form-select {
	height: 4.4em

}

.form-actions {
	margin: 0 0 3em;
}

.form-item {
	margin: 0 0 1.5em;
}