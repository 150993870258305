// Styles for Views
// -----------------------------------------------------------------------------




.view-portraits {
	margin-bottom: 4em;
	.views-row {
		width: 50%;
		float: left;
		position: relative;
		img {
			width:100%;
			height: auto;
			z-index:10;
		}
		.portrait-rollover {
			// display: none;
			opacity: 0;
			padding: 20px;
			width: 100%;
			position: absolute;
			background-color: $gray-dark;
			height: 100%;
			color: white;
			transition: all 0.3s ease;
			span {
				display: block;
				transition: all 0.6s ease;
				position: absolute;
			}
			.portrait-title {
				top: 30px;
			}
			.view-portrait {
				bottom: 10px;
				text-align: right;
				color: $gray-light;
			}
		}
	}
	.views-row:hover {
		cursor:pointer;
		.portrait-rollover {
			opacity: 1;
			.portrait-title {
				top:20px;
			}
			.view-portrait {
				bottom:20px;
				
			}

			// display: block;

		}
	}
}
.portrait-popup {
	background: $gray-darker;
	height: 100%;
	overflow: hidden;
	img {
		// width: 70%;
		// height: auto;
	}
	.portrait-pic {
		width: 70%;
		float: left;
		img {
			width: 100%;
			height: auto;
			// max-width: 550px;
			margin: 0 auto;
			display: block;
		}
		&.portrait {
			img {
				max-width: 560px;
			}
		}
	}
	.portrait-body {
		background: $gray-darker;
		width: 30%;
		float: right; 
		color:white;
		padding: 1em;
		i {
			color: $gray-lighter;
			// font-size: 1.2em;
		}
	}
}

#cboxLoadedContent {
	background-color: #222;
}
#cboxOverlay {
	background-image: none;
}
#cboxOverlay:before {
	top: 18px;
}
@media only screen and (min-width : 768px) {
	.view-portraits {
		.views-row {
			width: 20%;
		}
	}
}
@media only screen and (max-width : 767px) {
	.portrait-popup {
		.portrait-body, .portrait-pic {
			width: 100%;
		}
		.portrait-body {
			i {
				font-size: 1em;
			}
		}

	}
}


.view-podcast-collections {
	margin-top: 2em;
	.views-field-name {
		text-align: center;
		padding-bottom: 3em;
	  padding-top: 1em;
	  width: 86%;
	  margin: 0 auto;
		a {
			color: #2d323c;
			&:hover, &Lfocus, &:active {
				color: #2d323c;
			}
		}
	}

	.views-field-field-single-image {
		background-color: #000;
    width: 86%;
    margin: 0 auto;
    img {
    	background-color: #000;
    	transition: 0.3s ease;
    	&:hover, &Lfocus, &:active {
    		opacity: 0.7;
    	}
    }
	}
}

.view-video {
	.views-field.views-field-view-node {
	    position: absolute;
	    /* top: 316px; */
	    /* left: 123px; */
	    width: 100%;
	    text-align: center;
	    font-size: 0.8em;
	    bottom: 2em;
	    margin: 0 -15px;
	}

	.col-md-4 {
		position: relative;
		.views-field-title {
			text-align: center;
			padding-bottom: 3em;
			padding-top: 1em;
			width: 86%;
			margin: 0 auto;
		}

		.views-field-field-url {
			background-color: #000;
			width: 86%;
			margin: 0 auto;
			.file-video {
				opacity: 0.9;
				transition: 0.3s ease;
				img {
					width: 100%;
				}
			}
		}
		&:hover {
			.views-field-field-url {
				.file-video {
					opacity: 0.7;
				}
			}
			.views-field-title {
				text-decoration: underline;
			}

		}
		.views-field-field-url-1 {

			a {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 80%;
						border-radius: 0px;

						&:hover, &:focus, &:active {
							background-color: transparent;
							box-shadow: none;
						}
						.video {
							margin: 16% 0 0;
						}
					}
		}
	}
}


.view-featured-videos-thumbs {
	.file-video {
		height: inherit;
		padding: 0;
	}
	&.view-display-id-block_1 { //Vertical
		margin-bottom: 6em;
		overflow-y: scroll;
		overflow-x: hidden;
		position: relative;
		padding-right: 1em;
		.views-row {
			.views-field-filename {
				text-align: center;
				line-height: 1em;
				margin: 0.4em 5px 1em;
			}

			img {
				border: 5px solid rgba(0,0,0,0);
			}

			&:hover, &:focus, &:active, &.active {
				text-decoration: underline;
				img {
					border: 5px solid rgba(255,255,255,0.2);
				}
			}
		}


		
	}
	&.view-display-id-block_2 { //Horizontal
		padding: 0 15px;
		margin-bottom: 2em;
		.owl-item {
			cursor: pointer;

			.views-field-filename {
				text-align: center;
				line-height: 1em;
				margin: 0.4em 5px 1em;
			}

			img {
				margin: 5px 5px 10px 0px;
				border: 5px solid rgba(0,0,0,0);
			}

			&:hover, &:focus, &:active {
				text-decoration: underline;
				img {
					border: 5px solid rgba(255,255,255,0.2);
				}
			}
		}
	}

	.views-row {
		cursor: pointer;

		&:first-child img {
			margin-top: 0; 
		}
	}
	.owl-carousel { touch-action:none !important; }
}

.view-featured-videos {
	.views-field-field-featured-video {
		position: relative; 
		padding-bottom: 56.25%; 
		height: 0; 
		overflow: hidden; 
		max-width: 100%; 
	}


	iframe {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
	}	
}
.file-video {
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%;
	iframe {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
	}	 
}



.video-js {
	background-color: transparent;
	height:0;
}


// Owl Carousel
.owl-theme {
	.owl-controls .owl-page {
		vertical-align: middle;
	}

	.owl-controls .owl-page span {
		background-color: $gray-dark;
		height: 8px;
		width: 8px;
		opacity: 0.2;
		transition: all 0.3s ease;
		margin: 5px 10px;
	}
	.owl-controls .owl-page.active span, 
	.owl-controls.clickable .owl-page:hover span {
		height: 12px;
		width: 12px;
		opacity: 1;
		margin: 5px 8px;
	}

	.views-field-title,
	.views-field-field-job-title {
		color: $gray-dark !important;
	}

}




// Testimonials 

.view-testimonials {
	text-align: center;
	.view-header {
		color: $gray-light;
		margin-bottom: 2em;
	}

	.views-field-body {
		margin-bottom: 1em;
		font-size: 1.9em;
		line-height: 1.2em;
		font-family: $font-family-bold;
		color: $gray-dark;
	}
	.views-field-title {
		font-size: 1.1em;
		text-transform: uppercase;
	}
	
	.views-field-field-job-title {
		font-size: 0.8em;
		text-transform: uppercase;
	}
	.owl-nav {
		width: 108px;
		height: 46px;
		display:block;
		margin: 1em auto;
	}
  .owl-carousel .owl-nav .owl-prev {
		background: url(../images/icons/SVG/Genio_Icons.svg);
    background-color:$gray-lighter;
    background-size: 510px 30px;
    background-position: -60px 1px;
    transform: rotate(-180deg);
    background-repeat: no-repeat;
		margin: 0 4px;
		padding: 16px;
		border: 7px solid $gray-lighter;
		
		width: 33px;
    height: 33px;
    display: block;
    // background: rgba(45,50,60,0.6);
    position: relative;
    transition: 0.3s;
		border-radius: 3px;
		float: left;
		&:hover {
			background-color: $gray-light;
			border-color: $gray-light;
		}
	}
  .owl-carousel .owl-nav .owl-next {
		background: url(../images/icons/SVG/Genio_Icons.svg);
    background-color:$gray-lighter;
    background-size: 510px 30px;
    background-position: -60px 1px;
    background-repeat: no-repeat;
		margin: 0 4px;
		padding: 16px;
		border: 7px solid $gray-lighter;
		
		width: 33px;
    height: 33px;
    display: block;
    // background: rgba(45,50,60,0.6);
    position: relative;
    transition: 0.3s;
		border-radius: 3px;
		float: left;
		&:hover {
			background-color: $gray-light;
			border-color: $gray-light;
		}
	}
}

// Our Team

.view-our-team {
	margin-bottom: 6em;

	.bio {
		display: none;
		padding: 0 30px 0 0;
	}
	.team-member {
		transition: opacity .5s ease-in-out;
		cursor: pointer;
		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
	.disabled {
		opacity: 0.3;
		cursor: default;
	}
	.close-bio {
		cursor: pointer;
		content: ' ';
		display: inline-block;
		height: 20px;
		width: 20px;
		background-image: url($icons);
		background-size: 340px 20px;
		background-position: -260px 0px;
		background-repeat: no-repeat;
		top: 16px;
		left: 95%;
		position: relative;
		margin: 0 0 1em 1em;
		&:hover {
			background-position: -240px 0px;
			cursor: pointer;
		}
	}
}


// Load More Pager

.pager-load-more.pager {
	margin-bottom: 4em;
	li {
		margin: 0;
	}

	.pager-next>a{
		border: none;
		color: $gray;
		text-transform: uppercase;
		font-size: $font-size-small;
		font-family: $font-medium;
		padding: 10px 16px;
		border-radius: 6px;

		&:hover {
			background-color: rgba(0,0,0,0.1);
		}

		&:before {
			content: '';
		  display: inline-block;
		  height: 20px;
		  width: 20px;
		  background-image: url($icons);
		  background-size: 340px 20px;
		  background-position: -280px 0px;
		  background-repeat: no-repeat;
		  top: 4px;
		  left: -3px;
		  position: relative;
		}

		.ajax-progress {
			display: block;
		}
	}
}

// Research and Evidence
.pane-research-and-evidence {
	margin: 6em auto 4em;
}


.view-research-and-evidence {
	.pager {
		clear: both;
		display: block;
		.pager-first, .pager-last, .pager-ellipsis, .ajax-progress {
			display: none;
		}

		.pager-next a {
				&:after {
					content: '';
			    display: inline-block;
			    height: 20px;
			    width: 20px;
			    background-image: url($icons);
			    background-size: 340px 20px;
			    background-position: -120px 0px;
			    background-repeat: no-repeat;
			    top: 3px;
			    left: 13px;
			    position: relative;
				}
		}
		.pager-previous a {
				&:before {
					content: '';
			    display: inline-block;
			    height: 20px;
			    width: 20px;
			    background-image: url($icons);
			    background-size: 340px 20px;
			    background-position: -180px 0px;
			    background-repeat: no-repeat;
			    top: 3px;
			    left: 13px;
			    position: relative;
				}
		}
	}



	.views-submit-button {
		display: none;
	}
	.views-exposed-widget{
		width: 100%;

		.views-widget > .form-item {
			width:100%;
			margin-bottom: 0.4em;
		}
		label {
			font-family: $font-medium;
			font-size: 0.7em;
			text-align: center;
			display: block;
		}
		.bef-select-as-links > .form-item {
	    // width: 100%;
	    text-align: center;
	    .form-type-bef-link {
  			margin-top: 0.6em;
  			border: 0 none;
  			display:inline-block;
  	    *display:inline; /*IE7*/
  	    *zoom:1; /*IE7*/
  	    // float: none;
  	    -webkit-font-smoothing: antialiased;
		    a {
		    	border: 0 none;
		    	color: $gray-lighter;
		    	text-transform: uppercase;
		    	font-size: 0.8em;
		    	font-family: $font-medium;
		    	border-radius: 0;
		    	padding: 0.4em 0.8em 0.2em;
		    	transition: all 0.3s ease;
		    	display: block;
		    }
				a:hover,
		    a.active {
		    	background-color: $genio-purple;
		    	color: #fff;
		    	border: 0 none;
		    	text-decoration: none;
		    }
	    }

		}
	}



	&.view-display-id-block_1  {
		.publication {
			margin: 2em auto;
		}
	}
	.publication {
		border-bottom: 4px solid $gray-dark;

		.cover {
			border-left: 10px solid;
			padding: 2.4em 1em;
			position: relative;
			box-shadow: 1px 1px 9px rgba(0,0,0,0.34);
			// width: 200px;
			height: 334px;
			cursor: pointer;
			transition: all .2s;
			transition-timing-function: ease-in;
			background-repeat: no-repeat;

			&:hover {
				box-shadow: 6px 6px 9px rgba(0,0,0,0.24);
				// top: -1px;
				// left: -1px;
				a {
					text-decoration: none;
				}
			}

			.pub-area{
				color: white;
				text-transform: uppercase;
				font-size: 0.6em;
				font-family: $font-medium;
				padding: 6px 9px 2px;
				display: inline-block;
				position: absolute;
				top: 0;
				left: -10px;
			}
			.pub-title h4 {
				font-size: 1.06em;
				line-height: $line-height-computed;
				margin-top: 0;

				a {
					color: inherit;
				}
			}
			.pub-author {
				font-size: $font-size-small;
				-webkit-font-smoothing: auto;
				display: block;
				line-height: 1.4;
				margin-bottom: 0.5em; 
			}
			.pub-year {
				font-size: $font-size-small;
				-webkit-font-smoothing: auto;
				display: block;
				line-height: 1.4;
				border-top: 1px solid rgba(0,0,0,0.1);
				padding-top: 0.5em; 
				color: rgba(0,0,0,0.5);
			}
		}


		&.Mental .cover{
			background-color: #EBF7F5;
			border-color: #94D5CA;
			background-image: url("../images/icons/SVG/Genio_Report-BG-Mental-Health.svg");
			background-position: right bottom;
			.pub-area {
				background-color: #79CABD;
			}
		}
		&.Dementia .cover{
			background-color: #D9ECF2;
			border-color: #339CBA;
			background-image: url("../images/icons/SVG/Genio_Report-BG-Dementia.svg");
			background-position: right bottom;
			.pub-area {
				background-color: #0083A9;
			}
		}
		&.Homelessness .cover{
			background-color: #EFF0F7;
			border-color: #A9ADD6;
			background-image: url("../images/icons/SVG/Genio_Report-BG-Homelessness.svg");
			background-position: right bottom;
			.pub-area {
				background-color: #9398CC;
			}
		}
		&.Disability .cover{
			background-color: #FCF3E0;
			border-color: #F1BF59;
			background-image: url("../images/icons/SVG/Genio_Report-BG-Disability.svg");
			background-position: right bottom;
			.pub-area {
				background-color: #EEAF30;
			}
		}
		&.cross-programme .cover{
			background-color: #f7f6f5 !important;
			border-color: #d2cec8 !important;
			background-image: url("../images/icons/SVG/Genio_Report-BG-Multi.svg") !important;
			background-position: right bottom;
			.pub-area {
				background-color: #b5b1ac !important;
			}
		}
	}
}


// Projects List

.view-projects-list {
	padding-bottom: 6em;
	.view-header {
		color: $gray-lighter;
	}
	.project {
		.title {
			cursor: pointer;
			padding: 1em 0;
			border-top: 1px solid $gray-lighter;
		}
		&.open .row{
			background-color: #f9f9f9 !important;
		}
		small {
			color: $gray-lighter;
		}
	}
	.description {
		display: none;
	}

	table {
		border: 0 none;
		tr {
			background: transparent;
			.views-field-title  {
				max-width: 400px;
				cursor:pointer;
				min-width: 240px;
				white-space: normal !important;
				.description {
					max-width: 400px;
					padding-top: 1em;
				}
			}
			.views-field-field-project-type {
				// max-width: 300px;
				min-width: 124px !important;
				white-space:normal !important;
				small {
					font-size: 75%;
					color: $gray-light;
				}
			}
			.views-field-field-project-amount,
			.views-field-field-year {
				text-align: right;
			}
			th {
				white-space: nowrap;
				color: $gray-lighter;
				font-size: 1.2em;
				a {
					color: $gray-light;
					img {
						margin: -10px 0 0 5px;
					}
				}

			}
			th,td {
				border: 0 none;

				h5 {
					margin: 0.12em 0;
				}
			}
		}
	}
}


// Map
.view-projects-map {
	.views-exposed-widgets {
		margin-bottom: 0;
	}
	.views-exposed-widget {
		display: none;
	}
}


.block-views-exp-projects-list-page-1 {
	.views-exposed-form,
	.view-filters {
		overflow: hidden;
	    
    .views-exposed-widgets {
    	margin: 0;

    	.ajax-progress {
    		display: none;
    	}

    	.views-exposed-widget {
    		transition: all 0.3s ease;
    		padding: 5px 10px;


    	}
    	.views-widget-filter-field_programme_area_value,
  		.views-widget-filter-field_project_type_value {
				& > label {
					content: '';
					display: inline-block;
					height: 30px;
					width: 30px;
					background-image: url(../images/icons/SVG/Genio_Icons.svg);
					background-size: 468px 57px;
					background-position: -54px -13px;
					background-repeat: no-repeat;
					top: 0px;
					right: -30px;
					position: absolute;
					background-color: rgba(0,0,0,0.3);
					padding: 0px;
					cursor: pointer;
					text-indent: -999px;
					color: transparent;
					transform: rotate(180deg);
					
				}
				// transform: translateX(-100%);
    		&.hide-filter {
					transform: translateX(-100%);

					&>label {
						transform: rotate(0deg);
					}
				}

  		}	
  		.views-widget-filter-field_cho_area_value {
  			& > label {
  				content: '';
  				display: inline-block;
  				height: 30px;
  				width: 30px;
  				background-image: url(../images/icons/SVG/Genio_Icons.svg);
  				background-size: 468px 57px;
  				background-position: -54px -13px;
  				background-repeat: no-repeat;
  				top: 0px;
  				left: -30px;
  				position: absolute;
  				background-color: rgba(0,0,0,0.3);
  				padding: 0px;
  				cursor: pointer;
  				text-indent: -999px;
  				color: transparent;
  				transform: rotate(180deg);
  			}
  			// transform: translateX(100%);
    		&.hide-filter {
					transform: translateX(-100%);

					&>label {
						transform: rotate(0deg);
					}
				}

  		}	
    }
    
    .views-submit-button {
    	margin: 0;
    	padding: 0 !important;
    }
    #edit-programme-area-wrapper,
    #edit-field-project-type-value-wrapper {
    	width: 300px;
    	max-width: 70%;
    	position: absolute;
    	padding: 12px 1em;
    	left: 0;
    	z-index: 100;
    	background: white;
    	font-size: 16px;
    	font-family: todaysb-medium;

			.form-item-programme-area,
    	.form-item-field-project-type-value {
    		.form-item {
    			// margin-bottom: 0.5em;
    			input {
    				display: none;
    			}
    			label {
						background: url('/sites/all/libraries/gmap_markers/gray_x2.png') no-repeat left top;
						padding-left: 40px; 
						display: block;
						padding-top: 5px;
						font-weight: normal;
						color: $gray-lighter;
						background-size: 30px 30px;
						// line-height: 40px;
						margin-bottom: 1px;

    				&:hover {
    					cursor: pointer;
    					// text-decoration: underline;
    				}
    			}
    		}

				.form-item-edit-programme-area-disability.highlight,
				.form-item-edit-field-project-type-value-disability.highlight {
					label {
						background: url('/sites/all/libraries/gmap_markers/yellow_x2.png') no-repeat left top;
						background-size: 30px 30px;
						color: $gray;
					}
				}
				.form-item-edit-programme-area-mental-health.highlight,
				.form-item-edit-field-project-type-value-mental-health.highlight {
					label {
						background: url('/sites/all/libraries/gmap_markers/turq_x2.png') no-repeat left top;
						background-size: 30px 30px;
						color: $gray;
					}
				}
				.form-item-edit-programme-area-dementia.highlight,
				.form-item-edit-field-project-type-value-dementia.highlight {
					label {
						background: url('/sites/all/libraries/gmap_markers/blue_x2.png') no-repeat left top;
						background-size: 30px 30px;
						color: $gray;
					}				
				}
				.form-item-edit-programme-area-homelessness.highlight,
				.form-item-edit-field-project-type-value-homelessness.highlight {
					label {
						background: url('/sites/all/libraries/gmap_markers/purple_x2.png') no-repeat left top;
						background-size: 30px 30px;
						color: $gray;
					}	
				}
				.form-item-edit-programme-area-homelessness, 
				.form-item-edit-field-project-type-value-homelessness {
					// display: none;
				}


    	}
    }
    #edit-county-wrapper {
    	border-left: 8px solid $gray-lighter;
    	top: 212px;
    	padding-top: 10px;
			// transform: translateX(100%);
  		&.hide-filter {
				transform: translateX(-100%);
			}
			label {
				position: absolute;
				top: -14px;
				left: 7px;
				font-size: 15px;
			}
			.views-widget {
				padding-left: 10px;
			}
			.description {
				top: 10px !important;
			}

    }
    #edit-type-wrapper {
    	top: 277px;
    	padding-top: 9px;
			// transform: translateX(100%);
  		&.hide-filter {
				transform: translateX(-100%);
			}
			.views-widget {
				padding-left: 10px;
			}
			.description {
				top: 8px !important;
			}
    }
    .views-widget-filter-field_year_value {
    	top: 340px;
			// transform: translateX(100%);
			text-align: left; 
  		&.hide-filter {
				transform: translateX(-100%);
			}
			.form-item {
				width: 100%;
			}
			.views-widget {
				padding-left: 10px;
			}
    }
    .views-submit-button {
    	top: 388px;
			// transform: translateX(100%);
  		&.hide-filter {
				transform: translateX(-100%);
			}
			input {
				width: 100%;
				border-radius: 0;
				background-color: $gray-lighter;
				color: white;
				margin-top: 20px;
				&:hover {
					background-color: $gray-light;

				}
			}
    }
    .views-submit-button,
    #edit-county-wrapper,
    #edit-type-wrapper,
    .views-widget-filter-field_year_value,
    #edit-cho-area-wrapper {
    	position: absolute;
    	left: 0;
    	z-index: 100;
    	background: white;
    	width: 300px;
    	max-width: 70%;
    	text-align: center;
    	.description {
    		font-size: 14px;
    		color: $gray-lighter;
    		position: absolute;
    		top: 4px;
    		left: 21px;
    		text-transform: uppercase;
    	}

	    .form-select 	{
	    	height: 2em;
	    	margin: 15px 0 6px;
	    }

    }
    #edit-cho-area-wrapper {
    	top: 143px;
    	border-left: 8px solid $gray-lighter;

    	.views-widget {
    		padding-left: 10px;
    	}


			label {
				margin-bottom: 1em;
				display: none;
			}
    	.form-type-bef-link {
    		padding: 0.4em 0em 0.1em;
    		border-top: 1px solid $gray-lighter;
    		text-align: left;
    		width: 200px;
    		a {
    			color: $gray-lighter;
    			&:hover, &.active {
    				text-decoration: none;
    				color: $gray;
    			}
    		}
    		&:first-child {
    			border-top: 0 none;
    		}
    	}
    }

    select {
    	background: transparent;
    	border: 0 none;
    	box-shadow: none;
    	overflow: hidden;
    	-webkit-appearance: none; 
    	-moz-appearance: none;

    	option {
    		padding: 0.5em 1em;

    		&:hover,
    		&:focus,
    		&:active,
    		&:checked {
    			background: transparent;
    			background: transparent;
    		}
    	}
    }
	}	
}
.node-type-event,
.node-type-article,
.front {
	.view-news-events {
		.view-header, .view-filters {
			display: none;
		}
	}
}


.view-video,
.view-news-events {
	.unpublished {
		opacity: 0.3;
	}
	.views-field-status {
		width: 100%;
		padding: 0;
		color: white;
		text-transform: uppercase;
		font-size: 12px;
		text-align: center;
		max-width: 100%;
		line-height: 1.6em;
		position: absolute;
		top: 193px;
		left: 0;
		span {
			background: red;
			padding: 5px 5px 2px;
		}
	}
	.views-submit-button {
			margin: 0;
			padding: 0;
			position: absolute;
			bottom: 0;
		.ajax-progress .throbber {
			background-position: center center;
		}
	}
	.views-exposed-widgets {
		position: relative;
	}
	.views-exposed-widget {
		width: 100%;
		text-align: center;
		
		.bef-select-as-links > div.form-item {
			.form-item {
				display: inline-block;
				margin-bottom: 2em;
				a {
					color: $gray-lighter;
					padding: 0.6em 0.8em 0.3em;
					transition: all 0.3s ease;
					text-transform: uppercase;
					font-family: $font-medium;
					-webkit-font-smoothing: antialiased;
					margin-left: 0px;
					
					&:hover, &.active {
						background-color: $gray-dark;
						color: white;
						text-decoration: none;
					}
				}
			}
		}

	}

	.view-footer {
		text-align: center;
		 a {
		 	margin-top: 1em;
		 }
	}

	.views-field-created-1 {

		background: url(../images/icons/SVG/Genio_Circle.svg) no-repeat center /contain;
		// display: flex;
		height: 180px;
		align-items: center;
		margin-bottom: 3em;

		.field-content {
			margin: 0 auto;

			.big-day {
				font-size: 3.3em;
				font-family: TodaySB-Bold;
				display: block;
				margin: -8px 0 -22px;
				padding-top: 28px;
			}
			.month-year {
				font-size: 1.2em;
				font-family: TodaySB-Medium;
			}
		}
	}

	.views-field-title {
		margin-bottom: 1em;
		h4 {
			font-family: TodaySB-Bold;
			-webkit-font-smoothing: antialiased;

		}
		a {
			color: $gray-dark;
		}
	}
	.views-field-body {
		font-size: $font-size-small;
		font-family: $font-medium;
		word-break: break-word;
	}
}

// Site Info

.view-site-info {
	.views-field-field-eu-partner-logos {
		img {
			max-height: 90px;
			width: auto;
			filter: grayscale(100%) brightness(0%);
			opacity: 0.7;
		}
	}
	.views-field-field-partners-logos {
		img {
			max-height: 90px;
			width: auto;
			// margin: 0 2.5em;
		}
	}

 .views-field-field-contact-info {
		// font-family: TodaySB-Medium;
		a {
			color: inherit;
		}
	}
	
}

.view-id-site_info {
	h3 {
		font-size: 1.45em;
	}
}

