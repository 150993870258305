// Styles for blocks.
// -----------------------------------------------------------------------------



// New and Events Block 

.block-views-news-events-block {
	padding: 2.7em 0;

	.block__title {
		text-align: center;
		font-size: 2em;
		margin-bottom: 1.8em;
	}
}

.block-views-site-info-block {
	padding: 2em 0 2em;
}


// Programme Areas 

.block-views-site-info-block-4 {
	.bl-hot:hover {
		color: $gray-dark;
	}
	margin: 4em 0 4em;
	.block__title {
		// font-size: 1.9em;
		text-align: center;
		margin: 1.6em 0;
	}
	.col-md-3 {
		padding-bottom: 1.4em;
		font-family: $font-medium;
		color: $gray-light;
		transition: 0.1s color;
	}
}

.block-menu-menu-multimedia {
	.nav>li>a {
		padding: 0.33em 0;
	}
	.block__content {
		padding-top: 1.3em !important;
	}
}

.page-node-1989 .block-block-29 {
	display: none;
}