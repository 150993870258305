// Styles for fields.
// -----------------------------------------------------------------------------





// mp3s 

.field-name-field-mp3-file {
	ul.graphic li a.sm2_link {
		background-color: rgba(45,50,60,0.6);
		padding: 10px 34px 5px;
		background-position: 9px 12px;
		
		&:hover {
			background-color: rgba(45,50,60,1);
		}
	}

	ul.playlist li {
		font-size: inherit;
		background-color: lighten($gray-dark, 15%);
		color: white;
		border-radius: 3px;
		padding-bottom: 0;

		&::before {
			content: "►";
			display: inline;
			float: left;
			width: 24px;
			height:24px;
			line-height: 1.4em;
			font-weight: bold;
			font-family: arial;

		}

		&:hover {
			background-color: $gray-dark;
		}
		& > a {
			color: white;
		}


		.timing {
			height: auto;
			background-color: transparent;
			border-color: white;
		}
		&.sm2_paused {
			background-color: lighten($gray-dark, 30%);
			padding-bottom: 0.25em;
			&::before {
				content: "| |";
				
			}
			&:hover {
				background-color: $gray-dark;
			}
		}
		&.sm2_playing, 
		&.sm2_playing:hover {
			background-color: $gray-dark;

			padding-bottom: 0.25em;
		}
		.controls .statusbar {
			cursor: -webkit-grab;
			background-color: #fff;
			.loading {
				background-color: #bbb;
			}

			.position {
				background-color: lighten($gray-dark, 30%);
				border-color: lighten($gray-dark, 30%);

			}
		}

	}


}




.field-name-field-contact-links {
	text-align: center;
}

.field-name-field-extra-features {
	margin-top: 6em;
}

.pane-entity-field .field-name-field-mp3-file ul.graphic {
	padding-left: 0;
	li:before {
		display: none;
		
	}
}