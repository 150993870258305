// Page Layout
// -----------------------------------------------------------------------------

// EU Notice 

#eu-notice {
	// margin: 0 auto 2em;
	#eu-logo {
		max-height: 90px;
		margin-bottom: 1em;
	}
}


// New header design
.page-node-1844 {
 &.node-type-homepage #header {
	 .region-header-main {
		&.region {
			min-height: calc(76vh - 122px);
		}
	
	 h1 {
		 margin-top: 20vh;
	 }
	}

	.block-views-above-fold-block, 
	.block-views-below-fold-block {
		min-height: calc(24vh);
		background: white;
		color: $text-color;
		padding: 5vh 0 2vh;
		div {
			&>h1:first-child, &>h2:first-child, &>h3:first-child, &>h4:first-child, &>h5:first-child, &>h6:first-child {
				margin-top: 0;
			}
			ul {
				padding-inline-start: 18px;
			}
		}
	}
 }

}


#callout {
	background-blend-mode: luminosity;
}
body {
	overflow-x: hidden;
}
.container {
	max-width:100%;
}

.media-element-container {
	position: relative;
	a {
		position: absolute;
		top: 0;
		width:100%;
		height: 100%;
		left: 0;
		z-index: 100;
	}
}

img.media-element {
	display: block;
  max-width: 100%;
  height: auto;
}

#autocomplete {
	background: #fff;
	border:0 none;
	padding: 8px 0px;
	ul li {
		background: transparent;
		margin: 0;
		padding: 6px 14px 2px;
		border: 0 none;
		cursor: pointer;
		&.selected {
			background: $genio-purple;
		}
	}
}
.webform-client-form input.form-autocomplete {
	background-position: right 13px center !important;
	&.throbbing {

		background-position: right 13px center !important;
	}
}
.front #header {
	.videomesh {
		// display: none;
	}
	.mesh-overlay {
		// display: none;
	}

}
.vjs-poster {
	background-position: 0 0 !important;
	background-size: cover !important;
}
canvas#canv {
    position: absolute;
    opacity: 0;
    z-index: -1;
    min-height: 100%;
    height: auto;
    max-width: 100%;
    transition: 7s all;
}

.page-node-done #content {
    margin: auto;
    max-width: 1170px;
    padding: 2em 0 4em;
}

.contact-map-container {
	// width: 100%;
	// margin: 50px 0 3000px;
	line-height: 0;
	margin-bottom: -1px;
}
.contact-map-container iframe{
	// width: 100%;
	// display: block;
	pointer-events: none;
	position: relative; /* IE needs a position other than static */
}
.contact-map-container iframe.clicked{
	pointer-events: auto;
}

// Maintenance Mode

.maintenance-page {
	margin-top: 3em;
	background-color: #fff;
}


// Contact map

.page-node-1858 {
	#main {
		padding-bottom: 0 !important;
		.form-actions {
			margin-bottom: 5em !important;
		}
	}
	.field-name-field-full-width-feature {
		p {
			margin: 0;
			line-height: 0;
		}
	}
}


.view-unpublished {
	position: relative;
	.unpublished {
		background: rgba(255,0,0,0.06);
		width: 100%;
		padding: 0em;
		color: white;
		text-transform: uppercase;
		font-size: 10vw;
		text-align: center;
		max-width: 100%;
		position: absolute;
	}
}



// Search Page

.search-info {
	width: 100%;
	text-align: right;
	color: $gray-lighter;

	.username {
		display: none;
	}
}
.page-mmm,
.page-our-work-projects-funded-through-genio,
.page-taxonomy, .page-multimedia, .page-user, .page-search {
	#header .other-title {
		display: block;
		margin: 1.37em 0 0.89em !important;
		font-size: 1.54em !important;
	}
}

body:not(.page-user) .user-picture {
	display: none;
}
.submitted {
	display: none;
}

#admin-menu-wrapper {
	min-height: 29px;
}

#main .region-content .action-links {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
	max-width: 1200px;
}

#back-top {
	a {
		width: 45px;
		height: 45px;
		display: block;
		background: rgba(45,50,60,0.6);
		position: relative;
		padding: 8px;
		transition: 0.3s;
		border-radius: 3px;
		&:hover {
			background: rgba(45,50,60,1);
		}

		span#link {
			display: none;
		}
		span#button {
			background: url($icons);
			background-color: transparent;
			background-size: 510px 30px;
			background-position: -58px -1px;
			transform: rotate(-90deg);
			background-repeat: no-repeat;
			width: 32px;
			height: 32px;
			margin: 0;
			opacity: 1;
			&:focus, &:hover {
				background-color: transparent;
			}
		}
	}
}
.page-our-work-projects-funded-through-genio,
.page-node-1849 {
	#back-top a span#link {
	    display: block;
	    position: absolute;
	    top: -35px;
	    left: 0;
	    line-height: 1.4em;
	}
}


.img-responsive img {
    display: block;
    max-width: 100%;
    height: auto;
}



html body.page-node-1587 {
	// margin-top: 0 !important;
	#admin-menu {
		// display: none;
	}
}

.flex-video {
    min-height: 600px;
    position: relative;
    width: 100%;
    overflow: hidden;
}
    
.flex-video iframe {
    position: absolute;
    width: 200%;
    height: 200%;
    top: 50%;
    left: -50%;
    transform: translate(0%, -50%);
}

#breadcrumb {
	display: none;
}

body {
	color: $gray-dark;
	font-family: $font-family-base;
	font-size: $font-size-base;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;

}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}



// Social links

.social {
	color: transparent;
	background: url(../images/icons/PNG/Genio_Social-Media-Icons.png) no-repeat top left;
	display: inline-block;
	text-indent: -9999px;
	width: 36px;
	height: 36px;
	background-color: $gray-dark;
	border-radius: 40px;
	margin: 1em 1.2em;
}

.rss {
	background-position: 0 0;
}

.vimeo {
	background-position: -37px 0;
}

.linkedin {
	background-position: -72px 0;
}

.facebook {
	background-position: -107px 0;
}

.twitter {
	background-position: -145px 0;
}


// ICONS 
.arrow {
	background: url(../images/icons/PNG/Genio_Icons.png) no-repeat;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: transparent;
	background-position: -80px 0;
	vertical-align: middle;
	margin-left: 8px;
}
.arrow-right:after {
	content: '';
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url($icons);
  background-size: 340px 20px;
  background-position: -120px 0px;
  background-repeat: no-repeat;
  top: 4px;
  left: 6px;
  position: relative;
}

.btn-lg.arrow-right:after {
	content: '';
  display: inline-block;
  height: 23px;
  width: 20px;
  background-image: url($icons);
  background-size: 250px;
  background-position: -84px 0px;
  background-repeat: no-repeat;
  top: 4px;
  left: 9px;
  position: relative;
}
.video {
	background: url(../images/icons/PNG/Genio_Play-Button_x2.png) no-repeat;
	width: 42px;
	height: 42px;
	display: inline-block;
	background-color: transparent;
	background-position: 0 0;
	vertical-align: middle;
	margin-left: 8px;
	background-size: contain;
}
.dark {
	background-color: $gray-dark;
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.arrow {
		background-position: -40px 0;
	}

	a {
		color: #fff;
	}
	.btn {
		color: #fff;

		&:hover {
			color: #fff;
			background: rgba(0,0,0,0.2);
		}
		&:active {
			box-shadow: none;
		}
	}
}

.color-overlay {
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	.arrow {
		background-position: -40px 0;
	}

	a {
		color: #fff;
	}
	.btn {
		color: #fff;

		&:hover {
			color: #fff;
			background: rgba(0,0,0,0.2);
		}
		&:active {
			box-shadow: none;
		}
	}
}
.light {
	background-color: white;
	.btn {
		color: $gray-dark;
		&:hover {
			background: rgba(0,0,0,0.1);
		}
	}
}
.cream {
	background-color: $genio-cream;
}
.btn {
	border-width: 0px;
	background-color: transparent;
	transition: background-color 0.3s;
	&:hover {
		background-color: inherit;
	}
}
.btn-lg, 
.btn-group-lg>.btn, 
.media-widget.btn-group-lg>a {
	font-size: 20px;
}

.genio-logo {
    background: url(../images/genio-logo-dark-text.png) no-repeat;
    height: 140px;
    display: inline-block;
    background-size: cover;
    width: 140px;
    margin-bottom: 1em;
}

.gm-style-iw ~ div {
	background: url(../images/close-grey.png) no-repeat;
	width: 27px !important;
	height: 28px !important;

	img {
		display: none;
	}
}
.gmap-popup {
    max-width: 300px;
    padding: 0.5em 0em 1em 1em;
    font-family: todaysb-light;
    font-size: 16px;
    line-height: 22px;

}

#bgvid {
	display: block;
	// position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	// z-index: -100;
	// transform: translateX(-50%) translateY(-50%);
}



.btn-lg {
	font-family: $font-medium;
}



// ************************
// Responsive media queries
// ************************

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 767px) {
	.media-element-container {
		width: 100%;
		margin-bottom: 2em;
		img {
			width: 100% !important;
			height: auto !important;
		}
	}

	.gmap-popup {
	    max-width: 300px;
	    padding: 0.2em 0em .5em .5em;
	    font-family: todaysb-light;
	    font-size: 16px;
	    line-height: 22px;

	}
	#featured .pane-testimonials {
	    margin: 5em 0 5em !important;
	}
	.view-testimonials .views-field-body {
		font-size: 1.2em !important;
	}
	.block-views-news-events-block {

		padding: 1.5em 0 0 !important;
		.block__title {
				margin-bottom: 1em !important;
			}
	}
	.view-news-events {
		padding: 0 30px;
		.view-footer {
			margin: 2em 0;
		}
	}
	.region-header-main {
		.views-field-field-header-text {
			padding: 0 15px;
			h1 {
						font-size: 1.4em !important;
					}
		}
	}

	#genio-overlay #genio-overlay-logo img,
	#header #menu-logo a img{
		// max-width: 80px;
		max-height: 87px;
		margin-top: 10px;
	}
	.view-news-events {
		.col-md-4 {
			margin-bottom: 3em !important;
		}
		.views-field-created-1 {
			margin-bottom: 0 !important;
			height: 140px !important;
			padding-top: 8px;
			.field-content {
				.big-day {
					font-size: 2.5em !important;
					padding-top: 28px !important;
				}
				.month-year {
					font-size: 1em !important;
					font-family: $font-family-base !important;
				}
			}
		}
		.views-field-title h4 {
			font-family: $font-medium !important ;
			font-size: 1.3em;
			// margin-top: 0 !important;
		}

		.views-field-body {
			font-family: $font-family-base !important;
		}
		.view-footer a {
			margin-top: 0 !important;
		}
	}
	.view-id-site_info h3 {
	    font-size: 1.3em !important;
	}
	.region-header-blocks .view-site-info {
		background-color: #fff;
		color: $gray-dark;
		text-align: center;
		padding: 1em 15px 1em;
		.header-block {
			padding: 1em 0 0.5em;

			strong {
				font-family: $font-medium;
				font-weight: normal;
			}
		}

	}

	#read-next {
		background-attachment: scroll !important;
	}
	#header .header-bg-image { 
		background-attachment: scroll;
	}
    .social {
    	margin: 1em 0.6em;
    }
    #genio-overlay, #footer-menu {
    	.region-footer-menu > .block > *:first-child {
        padding-top: 1.5em;
        margin-top: 0em;
        border-top: 1px solid rgba(255,255,255,0.2);
	    }
	    .region-footer-menu .block:nth-child(-n+2)>h4 {
        // padding-top: 1em;
        border-top: 0 none;
	    }
	    .block-menu-block {
				margin-bottom: 1em;
	    }
    }
    #actions .views-row > div:first-child:after {
    	// background-image: url('../images/icons/PNG/Genio_Divider-WhiteHorizontal_x2.png');
    }

    .callout .region-callout .wrapper {
			margin: 2.2em;
		}

		.view-site-info .views-field-field-partners-logos img {
			margin: 1em 0em;
			max-width: 100%;
			height: auto;
		}

		.region-top-left, .region-top-right {
			display: none;
		}
		.view-research-and-evidence {
			.publication {
				margin-bottom: 2.5em;
			}
		}
		.view-news-events {
			.col-md-4 {
				margin-bottom: 4em;
			}
		}

		.filter-by-programme .tab-content .tab-pane .container {
			&:nth-of-type(2) .row {

				.col-md-4:first-child {
					h1:before {
						background: none !important;
					}
				}
				.col-md-4:last-child {
					margin-bottom: -10px;
				}
			}
			.row {
				.col-md-4 {
					min-height: unset;
					h1 {
						&:before {
							background: url('../images/icons/SVG/Genio_Divider-White.svg') no-repeat center center / 6px 172px;
							transform: rotate(90deg);
					    content: ' ';
					    position: absolute;
					    left: 50%;
					    top: -108px;
					    width: 6px;
					    height: 172px;
						}
					}
				}
			}
		}

		.view-projects-list {
			table {
				font-size: 0.8em;
				tr th {
					font-size: 0.8em !important;
					text-transform: uppercase;
				}
				tr td {
					max-width: 100px;
					white-space: normal !important;
				}
			}

			word-wrap: break-word;
			margin-top: 0em;
			.view-header {
				.row > div {
					padding: 0 2px;
				}
				h5 {
					font-size: 10px;
					text-transform: uppercase;
				}
				.row div:nth-last-child(2),
				.row div:last-child {
					text-align: center;
				}
			}
			.project .title,
			.project .description {
				// font-size: 14px;
				&>div {
					padding: 0 2px;
				}
			}
			.project .title {
				div:nth-last-child(2),
				div:last-child {
					text-align: center;
				}
			}
		}
		#header .region-header-main {
			font-size: 1.1em;
			h1 {
				margin-top: 14vh;
				font-size: 1.5em;
			}
		}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	#genio-overlay #genio-overlay-logo img,
	#header #menu-logo a img{
		// max-width: 80px;
		max-height: 112px;
		margin-top: 2px;
	}
	#header .header-bg-image { 
		background-attachment: scroll;
	}
  #genio-overlay, #footer-menu {
  	.region-footer-menu > .block:nth-child(n+5) > *:first-child {
      padding-top: 1.5em;
      margin-top: 0em;
      border-top: 1px solid rgba(255,255,255,0.2);
    }
    .region-footer-menu .block:nth-child(-n+2)>h4 {
      // padding-top: 1em;
      // border-top: 0 none;
    }
    .block-menu-block {
			margin-bottom: 1em;
    }
    .block-menu-block-18 {
    	font-size: 0.9em;
    }
  }
  .block-views-site-info-block-4 {
  	margin: 4em 0 4em;
  	.block__title {
  		font-size: 1.9em;
  	}
  }
	#actions .views-row > div:first-child:after {
		// background-image: url('../images/icons/PNG/Genio_Divider-WhiteHorizontal_x2.png');
	}

	.callout .region-callout .wrapper {
		margin: 4.2em 0;
	}

	.view-site-info .views-field-field-partners-logos img {
		margin: 1em 0em;
	}
	.view-research-and-evidence {
		.publication {
			margin-bottom: 3.5em;
		}
	}

	.region-top-left, .region-top-right {
		display: none;
	}

	.view-news-events {
		.col-md-4 {
			// min-height: 475px;
			margin-bottom: 2em;
		}
	}
	.filter-by-programme .tab-content .tab-pane .container {
		&:nth-of-type(2) .row {

			.col-md-4:first-child {
				h1:before {
					background: none !important;
				}
			}
		}
		.row {
			.col-md-4 {
				min-height: unset;
				h1 {
					&:before {
						background: url('../images/icons/SVG/Genio_Divider-White.svg') no-repeat center center / 6px 172px;
						transform: rotate(90deg);
				    content: ' ';
				    position: absolute;
				    left: 50%;
				    top: -108px;
				    width: 6px;
				    height: 172px;
					}
				}
			}
		}
	}
	.view-projects-list {
		margin-top: 2em;

		.project .description,
		.project .title {
				// font-size: $font-size-large;

			}
	}
	#header .region-header-main {
		font-size: 1.3em;
		h1 {
			margin-top: 14vh;
			font-size: 1.99em;
		}
	}
	// Header Blocks

	.region-header-blocks {
		overflow: hidden;

		.view-site-info .view-content {
			display: table;
			border-spacing: 3px 0px;
			margin: 0 -6px 0 -6px

		}

		.view-site-info .view-content > div > div {
		 	padding: 0 3px 0 0px;
		 	float: none;
		 	display: table-cell;
		 	height: 100%;
		 	background: rgba(0,0,0,.8);

		 	.header-block {
		 		-webkit-font-smoothing: antialiased;
		 		-moz-osx-font-smoothing: grayscale;
		 		color: white;
		 		font-size: 20px;
		 		height: 100%;
		 		padding: 1.5em 1.5em 1em;

		 		a {
		 			color: $genio-pink;
		 			font-family: $font-medium;
		 		}
		 		strong {
		 			font-family: $font-family-bold;
		 			font-weight: normal;
		 		}
		 	}

		 	&:last-child {
		 		padding: 0;
		 	}
		 }
	}
	.fullwidthfeature {

		h3 {
			font-size: 2.28em;
			margin-bottom: 0.22em;
		}
	}
	.view-featured-videos-thumbs {
		&.view-display-id-block_1 {
			max-height: 300px;

			&:after {
				pointer-events: none;
				content: '';
				display: block;
				position: sticky;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 60px;
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2d323c+0,2d323c+100&0+0,1+100 */
				background: -moz-linear-gradient(top, rgba(45,50,60,0) 0%, rgba(45,50,60,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(45,50,60,0) 0%,rgba(45,50,60,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(45,50,60,0) 0%,rgba(45,50,60,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002d323c', endColorstr='#2d323c',GradientType=0 ); /* IE6-9 */
			}
		}
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
.block-views-site-info-block-4 {
	.col-md-3 {
		padding-bottom: 0em !important;
		padding-left: 2vw;
		padding-right: 2vw;
		min-height: 172px;
		padding-top: 2.8vw;
	}
	.views-row :not(:first-child) .col-md-3 {
		background: url('/sites/default/themes/genio2017/assets/images/icons/PNG/Genio_Divider-Grey.png') no-repeat;
	}
	.views-row :first-child .col-md-3 {
		padding-left:1vw;
	}
	.views-row :last-child .col-md-3 {
		padding-right:1vw;
	}

}
	#header .header-bg-image { 
		background-attachment: fixed;
	}
	.region-header-blocks .view-site-info .view-content>div>div .header-block {
		padding: 2.5em 2.5em 1em;
	}
	.view-projects-list {
		margin-top: 4em;

	}

  #genio-overlay, #footer-menu {
  	.region-footer-menu > .block:nth-child(n+7) > *:first-child {
      // padding-top: 1.5em;
      // margin-top: 0em;
      // border-top: 1px solid rgba(255,255,255,0.2);
    }
    .region-footer-menu > .block:nth-child(-n+6) > *:first-child  {
      padding-top: 0em;
      margin-top: 1em;
      border-top: 0 none;
    }
    .block-menu-block {
			// margin-bottom: 1em;
    }
    .block-menu-block-18 {
    	// font-size: 0.9em;
    }
  }
	#actions {
		// background-image: url('../images/icons/PNG/Genio_Divider-Grey.png');
		.views-row > div:first-child:after {
			background-image: none;
		}
	}

	.callout .region-callout .wrapper {
		margin: 6.2em 0;
	}
	.node-type-homepage #header {
		min-height: 700px;
	}
	.node-type-publication .radix-layouts-content .pane-node-title * {
		margin-top: 0;
	}
	.region-top-left, .region-top-right {
		display: block;
	}
	.filter-by-programme .tab-content .tab-pane .row .col-md-4 {
		h1:before {
			display: none;
		}
		min-height: 190px;
		background: url('../images/icons/SVG/Genio_Divider-White.svg') no-repeat left center /6px 172px;
		&:before, &:after {
			visibility: visible;
		}
	}
	.filter-by-programme .tab-content .tab-pane .container:last-child .row .col-md-4:before {
		background: url('../images/icons/SVG/Genio_Divider-White.svg') no-repeat center bottom / 6px 172px;
		transform: rotate(90deg);
    content: ' ';
    position: absolute;
    left: 50%;
    top: -86px;
    width: 6px;
    height: 172px;
    
	}
	.filter-by-programme {
		.tab-pane {
			.container {
				display: table;
				height: 100%;
				.row {
					display: table-row;
					height: 100%;
					.col-md-4 {
						display: table-cell;
						height: 100%;
						margin-bottom: 0.5em;
						&:first-child {
							background: none;
						}
					}

				}
				&:last-child {
					.row {
						.col-md-4 {
							background-position: left 22px;
					}
						}
				}
			}
		}
	}
	.view-featured-videos-thumbs {
		&.view-display-id-block_1 {
			padding-right: 1.2em;
			max-height: 393px;
		}
	}

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	.view-featured-videos-thumbs {
		&.view-display-id-block_1 {
			padding-right: 1.5em;
			max-height: 489px;
		}
	}

	.header-block {
		padding: 2.5em 2.5em 1em;
	}
	.node-type-homepage #header {
		min-height: 800px;
	}
	.view-research-and-evidence {
		.publication {
			margin-bottom: 4.5em;
		}
	}
	.filter-by-programme .tab-content .tab-pane .container:last-child .row .col-md-4:before {
		background: url(../images/icons/SVG/Genio_Divider-Horizontal-White.svg) no-repeat 1px center / 363px 6px;
		transform: rotate(0deg);
    width: 372px;
    height: 6px;
    top: 0px;
    left: 0%;
    margin: 0 15px;
	}

}
