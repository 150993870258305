// Styles for Nodes
// -----------------------------------------------------------------------------


// Impact Page tweaks
.page-node-1950 #fullwidthfeature {
	// display: none;
}

#sliding-popup.sliding-popup-bottom {
	background-color: $gray-dark;
	border-top: 1px solid rgba(255,255,255,1);

	font-size: 0.8em;

	.popup-content {
		#popup-text {
			margin-top: 0.8em;
			p {
				font-size: inherit;
			}
		}
		#popup-buttons {
			button {
				font-family: $font-medium; 
				text-shadow: none;
		    box-shadow: none;
		    border-radius: 6px;
		    background: rgba(0,0,0,0.3);
		    border: 0 none;
		    font-weight: normal;
		    padding: 7px 13px 4px;
		    transition: 0.1s;
		    color: white;
		    -webkit-font-smoothing: antialiased;
		    font-size: 1.1em;
		    margin-top: 0.9em;

				&:hover {
					background: rgba(0,0,0,0.8);
				}
			}
			
		}
	}


}



// Podcasts, Videos
.node-video.node-teaser ,
.node-podcast.node-teaser {
	padding: 0 0 2em;
	border-bottom: 1px solid $gray-lighter;

	&>h2 {
		margin: 1em 0 0;
		font-family: $font-medium;
		font-size: 1.6em;
	}
	.node-readmore {
		display: none;
  }
}

// Podcasts 
a#podcasts {
	color: $gray-darker;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
}
.pane-node-field-mp3-file {
	margin-bottom: 0.5em !important;
}
// Article, Podcasts
.node-type-event,
.node-type-team-member,
.node-type-video,
.node-type-article,
.node-type-podcast {

	.region-content {
		padding-bottom: 3em;
	}

	.radix-layouts-sidebar {
		* {
			font-size: $font-size-small;
		}

		.pane-title {
			font-family: $font-medium;
			margin-top: 0;
		}
	}


	.panel-pane{
		margin-bottom: 2em;
	}


}



// Webforms
.node-type-webform {
	#main {
		background-color: $genio-cream;
		padding-bottom: 4em;
	}
	.field-name-body {
		margin-bottom: 3em;
	}
	#main {
		padding-top: 3.5em;
	}
}

.node-webform {


}
.node-podcast,
.taxonomy-term-description,
.pane-content {
	strong {
		font-weight: normal;
		font-family: $font-medium;
	}

	ul:not(.nav-tabs):not(.nav-pills):not(.pagination):not(.pager):not(.graphic):not(.playlist):not(.chosen-results) {
		padding-left: 2em;

		li {
		   list-style: none;
		   position: relative;
		   margin-bottom: 0.5em;
		}
		li:before {
			content: '';
	    display: inline-block;
	    height: 20px;
	    width: 20px;
	    background-image: url($icons);
	    background-size: 340px 20px;
	    background-position: -120px 0px;
	    background-repeat: no-repeat;
	    top: 1px;
	    left: -26px;
	    position: absolute;
		}
	}
}
.dark .pane-content ul li:before {
	background-position: -160px 0px !important;
}
// Basic Page

.node-type-page {
	#main {
		.field-name-body {
			margin-bottom: 3.5em;
		}
	}
}

.page-node-1849 #header {
	margin-bottom: 0;
}

// Homepage
.admin-menu.node-type-homepage {
	#header {
		.region-header-main.region {
			min-height: calc(76vh - 122px);
		}
	}
}
.node-type-homepage {
	#header {
		min-height: unset;
		height: auto;

		.region-header-main.region {
			min-height: calc(76vh - 122px);
			height: auto;
		}
	}

	#main {
		padding: 0;
	}

	#page-header {
	    // margin: 2em 0;
	    // display: none;
	    .page-header .title {
	    	display: none;
	    }
	}
	#header .title {
		display: none;
	}

}


// Publication

.node-type-publication {
	.panel-panel {
	    margin-bottom: 1em;
	}
	#header {
		// margin-bottom: 3.5em;

		.other-title {
			display: block;
			text-align: center;
			margin: 1.37em 0 0.89em !important;
			    font-size: 1.54em !important;
			// margin: 1.37em 0 0.89em !important;
			// font-size: unset;
		}
	}
	.radix-layouts-column1 *,
	.radix-layouts-column2 * {
		font-size: $font-size-small;
	}
	.radix-layouts-column1 {
		.pane-title {
			margin: 0;
		}
		.pane-content {
			margin-bottom: 1em;
		}
	}

	.key-points { 
		background-color: $gray-dark;
		padding: 0.7em 1.2em;
		color: white;
		.pane-title {
			margin-bottom: 1em;
		}
		ul {
			padding-left: 1.6em;
		}
		ul li {
			margin-bottom: 1em;
		}
	}

	.findings-summary { 
		background-color: #dedede;
		padding: 0.7em 1.2em;
		color: $gray-dark;
		margin-top: 2em;
		.pane-title {
			margin-bottom: 1em;
		}
		ul {
			padding-left: 1.6em;
		}
		ul li {
			margin-bottom: 1em;
		}
	}

	#content {
		// margin-bottom: 5em;
	}
	.view-display-id-block_1 .pdf-download {
		opacity: 0.7;
	}
	.pdf-download {
		background: $gray;
		color: white;
		display: block;
		text-align: center;
		padding: 0.4em 0 0.6em;
		font-size: 0.93em;
		font-family: $font-medium;
		
		&:hover, &:active, &:focus {
			color: white;
			background: $gray-darker;
			text-decoration: none;
		}

		&:after {
			content: '';
	    display: inline-block;
	    height: 20px;
	    width: 20px;
	    background-image: url($icons);
	    background-size: 340px 20px;
	    background-position: -100px 0px;
	    background-repeat: no-repeat;
	    top: 3px;
	    left: 13px;
	    position: relative;
		}

	}
}





