// Styles for panels and panes.
// -----------------------------------------------------------------------------


.radix-layouts-sidebar.panel-panel {
    // margin-bottom: 6em;
}

.pane-research-and-evidence {
	.pane-title {
		text-align: center;
	}
}

