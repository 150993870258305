// Styles for Menus and Navs
// -----------------------------------------------------------------------------

//facing change menu tweak
.menu-block-6 .menu-mlid-17607 {
	display: block !important;
}
.menu-mlid-17607 {
	display: none !important;
}


.logged-in {
	.menu-link-log-in {
		display: none !important;
	}
}
.not-logged-in {
	.menu-link-my-account {
		display: none !important;
	}
}

.node-type-homepage .top-menu .menu li a {
	opacity: 1 !important;
	&:hover {
		text-decoration: underline;
	}
}

.mesh {
    // background: linear-gradient(180deg, rgba(0,0,0,0.75) 0%, transparent 130px);
}

.top-menu {
	font-size: 16px;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: grayscale;
	min-height: 122px;
	height:auto;
	background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 93%);

	.menu {

		li {
			display: none;
			align-items: center;
			padding: 50px 0 0px;
			// display: none;

			a {
				color: white;
				opacity: 0.7;
				text-shadow: 0 0 3px rgba(0,0,0,0.2);
				padding: 0 0.5em;
				transition: opacity 0.3s ease;
				font-size: 19px;
				line-height: 21px;
				&:hover {
					// text-decoration: underline;
					opacity: 1;
				}
				&:focus {
					opacity: 1;
				}
			}

			&.active a {
				background-color: transparent;
				opacity: 1;
			}

			&.active-trail a {
				opacity: 1; 
			}
		}
	}
}

.region-top-left ul {
	li:nth-child(-n+3) {
		display: flex;
	}
}

.region-top-right ul {
	float: right;

	li:nth-child(n+4) {
		display: flex;
	}
}


.nav>li>a {
	&:hover, &:focus {
		background-color: transparent;
		// text-decoration: underline;
	}
}

#genio-overlay,
#footer-menu {

	font-size: 16px;

	a {

		&:hover {
			text-decoration: underline;
		}
	}

	h4.block__title {
		font-size: 16px;
		font-family: TodaySB-Medium;
	}
	
	& > .container {
		padding: 0;
	}

	.nav-pills {
		li {
			a {
				padding: 0.33em 0;
			}
		}

		li.active {
			a, a:hover, a:focus {
				background-color: transparent;

			}
		}
	}

	.parent-mlid-11500,
	.parent-mlid-11019,
	.parent-mlid-11343,
	.parent-mlid-17473,
	.hide-first-link {
		.menu > .first {
			display: none;
		}
	}
}


#header .nav-stacked {

	li {
		float: left;
		margin: 0;
	}
}

.pane-menu-block {
	.menu {
		li {
			a {
				color: $gray-dark;
				padding: 0.4em 0;
				font-family: $font-medium;
				font-size: 0.9em;
				opacity: 0.36;
				-webkit-font-smoothing: antialiased;
				transition: opacity 0.3s ease;
				&.active, &:hover, &:active, &:focus {
					opacity: 1;
					color: $gray-dark;
				}
			}
			li.last {
				margin-bottom: 0;
			}
			&.active a {
				// color: inherit;
				background-color: inherit;
			}
			&.last {
				margin-bottom: 2em;
			}
			ul.menu {
				margin-left: 0.4em;
				display: none;
			}
			&.active-trail ul.menu{
				display: block;
			}
			&.expanded:not(.active-trail) .caret {
				border-top: 4px solid transparent !important;
				border-bottom: 4px solid transparent !important;
				border-left: 4px dashed !important;
				// border-top: 4px solid \9 !important;
			}
		}
	}
}









