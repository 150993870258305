// Styles for Tabs, Messages, ..etc
// -----------------------------------------------------------------------------


// Mailing List

#mailing-list .region-mailing-list {
	padding: 3em 0;
}


//Footer

.region-footer {
	p {
		margin: 0;
	}
}

// Featured 
#featured .panel-panel {
    min-height: auto;
}

// Video Feauture 

#videofeature {
	& > .container > .row:not(:empty) {
		margin-top: 2em;
		margin-bottom: 2em;
	}
	.pane-featured-videos-thumbs {

	}
	.field-name-field-featured-video-text {
		h2 {
			margin-bottom: 0.2em;
		}
	}
	.file-video {
		iframe {
			max-width: 100%;
			display: block;
			margin: 0 auto;
		}
	}
	.field-name-field-video-description {
		max-width: 670px;
		margin: 1em auto 0;
	}
	.view-featured-videos {
		.view-content {
			.disabled, .owl-carousel {
				// margin-bottom: 1.5em;				
			}
		}
		.owl-controls {
			margin-top: 2em;
			.owl-page span {
				background-color: #869791;
			}
		}
	}
}

// Colorbox 
body.admin-menu {

	#cboxOverlay {
		margin-top: 29px;
	}
}
#cboxTitle {
	display: none !important;
}
#cboxOverlay {
	// position: relative;
	background-color: $gray-dark;
	background-image: url(/sites/default/themes/genio2017/assets/images/genio-logo-white-text.png);
	background-position: top center;
	background-repeat: no-repeat;

	&:before {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;

    display: block;
		top: 49px;
		left: 15px;

		cursor: pointer;
		height: 20px;
		width: 20px;
		background-image: url($icons);
		background-size: 340px 20px;
		background-position: -20px 0px;
		background-repeat: no-repeat;

	}
}
#cboxLoadingOverlay {
	background: transparent;
}
#cboxClose {
	display: none;
}
#colorbox {
    box-shadow: 0px 0px 16px rgba(0,0,0,0.4);
}


// NEW HEADER

body {
	background-color: $gray-dark; 

}

body:not(.page-node-1932):not(.node-type-homepage):not(.page-node-1860) #main {
	padding-top: 2.4em;
}
.admin-menu #header.full-height {
	min-height: calc(76vh); 
	height: auto;
}
#header {
	color: white;
	// text-align: center;
	background-size: cover;
	position: relative;
	 // z-index: 0;

	&.full-height {
		min-height: 76vh; 
		height: auto;
	}

 .header-bg-image {
 	position: absolute;
 	top: 0;
 	left: 0;
 	min-width: 100%;
 	width: auto;
 	min-height: 76vh;
 	height: auto;
 	z-index: -3;
 	background-size: cover;

 	transition: 2s opacity;
 }
 .video-fallback-image {
 	position: absolute;
 	top: 0;
 	left: 0;
 	z-index: -4;
 	width: auto;
 	min-width: 100%;
 	height: auto;
 	min-height: 100vh;
 	object-fit: cover;
 	overflow: hidden;
 	img {
 		max-width: 100%;
 		width: auto;
 		height: auto;
 		min-height: 100vh;
 		object-fit: cover;
 	}
 }

	.other-title {
		display: none;
		margin-top: 1em !important;
		font-family: $font-family-bold;
	}

	h1.title {
		margin: 1.37em 0 0.89em !important;
		font-size: 1.54em !important;
		font-family: $font-family-bold;
	}
	.views-field-field-header-text {

		h1 {
			font-family: $font-family-bold;
		}
	}
	.views-field-field-bg-video-1 {
		video, .video-js {
			display: none;
			z-index: -100;
		}

		.video-js {
			display: block;

			min-width: 100%;
			min-height: 100vh;
			width: auto;
			height: auto;

			position: fixed;
			top: 50%;
			left: 50%;
			z-index: -100;
			transform: translateX(-50%) translateY(-50%);
			-webkit-transform: translateX(-50%) translateY(-50%);

			video {
				display: block;
				position: relative;
				width: auto;
				min-width: 100%;
				height: auto;
				min-height: 100vh;
				background: transparent;
			}
			// Target EDGE only
			@supports (-ms-ime-align:auto) {
				position: absolute !important;
				bottom: 50% !important;
				// top: unset !important;
				min-height: 100% !important;
			}

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			     /* IE10+ CSS styles go here */
			     position: absolute !important;
			     bottom: 50% !important;
			     // top: unset !important;
			     min-height: 100% !important;
			}
			
		}

	}
	
	.vjs-loading-spinner {
		display: none !important;
	}

	.videomesh {
		// display: none;
		opacity: 1;

		background: url('../images/videomesh.png');
		position: absolute;
		top: 0;
		left: 0;
		min-width: 100%;
		width: auto;
		min-height: 100%;
		height: auto;
		z-index: -1;
		background-attachment: fixed;
		-webkit-transform: translate3d(0,0,0);
		transition: 3s all;
	}
	.mesh-overlay {
		// display: none;

		background: rgba(45,50,60,1);
		position: absolute;
		top: 0;
		left: 0;
		min-width: 100%;
		width: auto;
		min-height: 100%;
		height: auto;
		z-index: -1;
		background-attachment: fixed;
		transition: 3s all;
	}

	.top-menu {
		// background-color: #fff;
		z-index: 10;

		#menu-logo {
			position: absolute;
			width: 100%;
			img {
				// max-height: 112px;
				// margin: 2px 0;
			}
		}
	}

	.region-header-main {

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-family: $font-medium;
		color: white;
		text-align: center;	
		line-height: 1.22em;
		// font-family: todaysb-medium;
		text-shadow: 0px 0px 8px rgba(0,0,0,0.2);

		a.btn {
			color: white;
			font-family: todaysb-medium;
			font-size: 0.8em;
			margin-top: -13px;
			&:hover {
				background: rgba(0,0,0,0.1);
			}
		}

		h1 {
			margin-bottom: 0.2em;
		}
	}
}


	
// Full Width Intro

.fullwidthintro .panel-pane {
    margin-bottom: 4em;
    margin-top: 4em;
}



// Key Contact 

#keycontact {
	.views-field-field-key-contact-name {
	    margin-top: 1.2em;
	}
	.views-field-field-key-contact-job-title {
		margin-bottom: 1em;
	}
	.pane-title {
		margin-top: 0;
	}
	.panel-pane {
		// margin: 3em 0;
	}
	.node-webform {
		h2 {
			display: none;
		}
	}

	// Target EDGE only
	@supports (-ms-ime-align:auto) {
		.node-webform {
			margin-top: -1em;
		}
	}
}

// Full Width Feature 



.map-bg {
	// background-image: url(../images/map-coloured-2-bw.png);
	background-image: url(../images/genio-map-color.png);
	background-size: cover;
	background-blend-mode: multiply;
	// background-color: rgba(83,46,99, .9);
	background-position: center center;
	padding: 3em 0 8em;
	position: relative;
	z-index: 0;
	color: $gray !important;
	a {
		color: $gray !important;
	}
	
	.container *, .container {
		z-index: 1;
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
     .map-bg {
				background-image: url(../images/map-IE.png) !important;
     }
     .map-bg:before {
     	top: 0;
     	z-index: -1;
         content: "";
         position: absolute;
         height: 100%;
         width: 100%;
         background: rgba(83,46,99, .86); /* THIS IS WHAT EVER OVERLAY COLOUR YOU WANT */
     }
}

// Target EDGE only
@supports (-ms-ime-align:auto) {
	.map-bg {
		background-image: url(../images/map-IE.png) !important;
	}
	.map-bg:before {
		top: 0;
		z-index: -1;
	   content: "";
	   position: absolute;
	   height: 100%;
	   width: 100%;
	   background: rgba(83,46,99, .86); /* THIS IS WHAT EVER OVERLAY COLOUR YOU WANT */
	}

}

// Extra Features 

.programme-areas {
	padding: 4em 0;
	.pane-title {
		text-align: center;
		font-size: 1.8em;
	}
}


// .tab-content>.tab-pane{
//     display:block;
//     visibility:hidden;
// }

// .tab-content>.active{
//     visibility:visible;
// }


// What we do

.what-we-do {

	// display: flex;
	div {

		height: auto;
		display: block;
	}
	h2 {
		font-size: 1.46em;
		margin-top: 1em;
	}
	a {
		color: $gray-light;
	}
	&>div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 6px;
    height: 172px;
    z-index: 1;
    opacity: 0.8;
    background-image: url('../images/icons/SVG/Genio_Divider-Grey.svg');
    background-repeat: no-repeat;
    background-position: 0% 0;
    background-size: 6px 172px;
    left: 100%; /* position the left edge of the element at the middle of the parent */
		top: 100px;
    transform: translate(-50%, -50%);
	}
	&>:last-child:after {
		display: none;
	}
}



// Filter By Programme

.filter-by-programme {

	.background-image {
		background-size: cover;
	}

	.color-overlay {
		background: rgba(83,46,99, 0.7);
		padding: 3em 0;
		color: white;
		// font-family: $font-medium;
	}
	.tab-pane-title {
		display: none;
	}

	.tab-content {
		p {
			font-size: 1.03em; line-height: 1.44em;
		}
		h1 {
			margin: 0.65em 0 0em 0;
			font-size: 2.9em;
			line-height: 1.03em;
			font-family: $font-family-bold;
		}


	}

	ul.nav-tabs {
		width:100%;
		text-align:center;
		border: 0 none;

		li {
			margin-top: 0.6em;
			border: 0 none;
			display:inline-block;
	    *display:inline; /*IE7*/
	    *zoom:1; /*IE7*/
	    margin-right:10px;
	    float: none;
	    -webkit-font-smoothing: antialiased;
	    a {
	    	border: 0 none;
	    	color: $gray-lighter;
	    	text-transform: uppercase;
	    	font-size: 0.8em;
	    	font-family: $font-medium;
	    	border-radius: 0;
	    	padding: 0.4em 0.8em 0.2em;
	    	transition: all 0.3s ease;
	    }
			a:hover,
	    &.active a {
	    	background-color: $genio-purple;
	    	color: #fff;
	    	border: 0 none;

	    }
		}
	}

}

// Extra Features 

.genio-project {
    background: white;
    border-top: 10px solid $genio-purple;
    padding: 1em 1.6em;
    margin: 3em auto 2em;
    a {
    	color: $gray-lighter;
    }
}



// Featured

#featured {


	.pane-testimonials {
		margin: 5em 0 8em;		
	}
}

// Read next

#read-next {
	background-size: cover;


	.field-item {

		&>h1, &>h2, &>h3, &>h4, &>h5, &>h6 {
			margin-top: 0;
		} 

	}
	.field-name-field-read-next-link {
		font-size: 1.13em;
		line-height: 1.3;
	}
	.color-overlay {
		background: rgba(0,0,0,0.5);
	}

	.container {
		cursor: pointer;
	}

	.panel-pane {
	    margin: 3em 0;
	}
}


// Map

#map {
	position: relative;
	overflow: hidden;
	width: 100%;
}

// Overlay

#open-genio-overlay-menu {
	float: left;
	margin: 49px 4px 0 0;
	cursor: pointer;
	content: '';
	display: inline-block;
	height: 20px;
	width: 20px;
	background-image: url($icons);
	background-size: 340px 20px;
	background-position: 0px 0px;
	background-repeat: no-repeat;
}
#close-genio-overlay {
	float: left;
	margin: 49px 4px 0 15px;
	cursor: pointer;
	content: '';
	display: inline-block;
	height: 20px;
	width: 20px;
	background-image: url($icons);
	background-size: 340px 20px;
	background-position: -20px 0px;
	background-repeat: no-repeat;
	position: absolute;
}
.admin-menu #genio-overlay {
	margin-top: 29px;
} 
#genio-overlay {
	display: none;
	min-height: 100vh;
	width: 100%;
	z-index: 1001;
	position: absolute;
	left: 0;
	top: 0;
	background-color: $gray-dark;
	#genio-overlay-logo {
		// margin-left: -1px;
	}

	a {
		color: white;
		&:hover {
			text-decoration: underline;
		}
	}

	// Target EDGE only
	@supports (-ms-ime-align:auto) {
		li {
			list-style: none;
		}
	}

	.overlay-content {
		.overlay-search {
			display: none;
			text-align: center;
			margin: 20vh auto 0;
			// position: relative;

			.form-actions {
				display: none;
			}
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
				.form-item-search-block-form {
					position: relative;
					label {
						overflow: hidden;
						font-family: $font-family-bold;
						font-size: 3em;
						height: 48px;
						color: $gray-light;
						clip: auto;
						width: 100%;
						line-height: 1;
						padding: 6px 12px;
						margin: 0;
						z-index: 1000;
						margin: 0px 0 0 0px;
					}	
				}
			}
			input {
				box-shadow: none;
				background-color: transparent;
				border: 0 none;
				font-size: 3em;
				height: 60px;
				font-family: $font-family-bold;
				text-align: center;
				line-height: 1;

				&:focus {
					box-shadow: none;
				}
			}
		}
		.region-footer-menu {
			display: none;
		}
	}
}






// #header {
// 	background-color: $gray-dark;

// 	& div {
// 		// border: 1px solid red;
// 	}

// 	background-size: cover;
// 	position: relative;
// 	// z-index: -1;

// 	.mesh {
// 		background: url('../images/videomesh.png');
// 		height: 100%;

// 		.top-menu {
// 			height: $menu-height;
// 			// position: absolute;
// 			min-width: 100%;
// 			width: auto; 
// 			z-index: 10;
// 		}

// 		.region-header-main {
// 			font-size: 1.5em;
// 			-webkit-font-smoothing: antialiased;
// 			-moz-osx-font-smoothing: grayscale;
// 			color: white;
// 			text-align: center;	
// 			font-family: todaysb-medium;
// 			text-shadow: 0px 0px 8px rgba(0,0,0,0.3);

// 			a.btn {
// 				color: white;
// 				font-family: todaysb-medium;
// 				font-size: 0.8em;
// 				&:hover {
// 					background: rgba(0,0,0,0.1);
// 				}
// 			}

// 			.view-background-videos {
// 				.views-field-field-bg-video {
// 					height: 100vh;
// 					z-index: -10;
// 					video {

// 						min-width: 100%;
// 						min-height: 100vh;
// 						width: auto;
// 						height: auto;

// 						position: fixed;
// 						top: 50%;
// 						left: 50%;
// 						z-index: -100;
// 						transform: translateX(-50%) translateY(-50%);


// 					}
// 				}

// 					.views-field-field-header-text {
// 				    margin: 0 auto;
// 				    width: 100%;
// 				    margin-top: 150px;
// 						position: absolute;
// 						color: white;
// 				    text-align: center;
// 				    padding: 0;
// 				    -webkit-font-smoothing: antialiased;
// 				    -moz-osx-font-smoothing: grayscale;
						
// 						h1.title {
// 							font-size: 1.3em;
// 							margin-top: 0;
// 						}
				    
				    
// 					}
// 			}
// 		}
// 	}
// }




.video-section {

	.region-video-section {
		margin: 3em 0 3em;
		font-size: 1.5em;
		line-height: 1.2em;
		font-family: todaysb-medium;
		h1 {
			margin-top: 0;
			// font-size: 1.8em;
		}
	}
}


.special-notice {
	// background-color: #ffe500;
	.covid19 {
			color: #2d323c;
			text-align: left;
			font-size: 0.76em;
			font-family: "TodaySB-Light","Helvetica Neue",Helvetica,Arial,sans-serif;
			-webkit-font-smoothing: auto;
			padding: 0 15px;
	}
	.region-special-notice {
		margin: 1.6em 0 2em;
    font-size: 1.5em;
    line-height: 1.2em;
    font-family: todaysb-medium;
	}
	a {
		color: $gray-dark;
	}
	strong {
		font-family: $font-medium;
	}
}

.callout {
	background-size: cover;

	.region-callout {

		.wrapper {
			background-color: #fff;
			padding: 1em 2em;
			font-size: 1.13em;
		}

		a {
			color: $gray-dark;
		}
		strong {
			font-family: $font-medium;
		}
	}
	.colored-overlay {
		background: rgba(0,173,155,0.4);
	}
}

.region-actions {
		padding: 2.5em 0 3em;
}
#actions {
	// display: none;
	.col-md-6 {
		padding-top: 1em;
		padding-bottom: 1em;
	}
	& > .container {
		padding: 0;
	}

	background-repeat: no-repeat;
	background-position: center center;
	color: #fff;

	.views-row {
		position: relative;
	}
	.views-row > div:first-child:after {
	    content: ' ';
	    display: block;
	    position: absolute;
	    width: 180px;
	    height: 11px;
	    z-index: 1;
	    opacity: 0.24;
	    background-repeat: no-repeat;
	    background-position: 50% 0;
	    -ms-background-size: contain;
	    -o-background-size: contain;
	    -moz-background-size: contain;
	    -webkit-background-size: contain;
	    background-size: contain;

      left: 50%; /* position the left edge of the element at the middle of the parent */

       transform: translate(-50%, -50%);
	}
}


#partners {
	// border-top: 1px solid white;
	
	.region-partners {
		overflow: hidden;
		padding: 2.2em 0;
	}
}
#eu-partners {
	.region.region-eu-partners {
		padding: 3.8em 0;
		overflow: hidden;
	}

}



#footer-menu {
	padding: 1em 0 0.6em;
}

#footer {
	margin: 0;
	padding: 0.8em 0 0.7em;
	font-size: 0.7em;
	font-family: TodaySB-Medium;
}

